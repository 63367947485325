import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { dispatch } from 'redux/actions/index';
import { connect } from 'react-redux';

import GunthyLogo from 'assets/images/spinner.gif';

const styles = {
  loadingPage: {
    height: '100%',
    width: '100%',
    backgroundColor: '#242424a8',
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: '999999999999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.7',
  },
  cssloadLoader: {
    width: '150px',
    height: '170px',
    'border-radius': '50%',
    '-o-border-radius': '50%',
    '-ms-border-radius': '50%',
    '-webkit-border-radius': '50%',
    '-moz-border-radius': '50%',
    perspective: '2400px',
  },
  cssloadInner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    'box-sizing': 'border-box',
    '-o-box-sizing': 'border-box',
    '-ms-box-sizing': 'border-box',
    '-webkit-box-sizing': 'border-box',
    '-moz-box-sizing': 'border-box',
    'border-radius': '50%',
    '-o-border-radius': '50%',
    '-ms-border-radius': '50%',
    '-webkit-border-radius': '50%',
    '-moz-border-radius': '50%',
  },
  cssloadOne: {
    left: '0%',
    top: '0%',
    animation: 'cssload-rotate-one 1.3s linear infinite',
    '-o-animation': 'cssload-rotate-one 1.3s linear infinite',
    '-ms-animation': 'cssload-rotate-one 1.3s linear infinite',
    '-webkit-animation': 'cssload-rotate-one 1.3s linear infinite',
    '-moz-animation': 'cssload-rotate-one 1.3s linear infinite',
    'border-bottom': '9px solid white',
  },
  cssloadTwo: {
    right: '0%',
    top: '0%',
    animation: 'cssload-rotate-two 1.3s linear infinite',
    '-o-animation': 'cssload-rotate-two 1.3s linear infinite',
    '-ms-animation': 'cssload-rotate-two 1.3s linear infinite',
    '-webkit-animation': 'cssload-rotate-two 1.3s linear infinite',
    '-moz-animation': 'cssload-rotate-two 1.3s linear infinite',
    'border-right': '9px solid white',
  },
  cssloadThree: {
    right: '0%',
    bottom: '0%',
    animation: 'cssload-rotate-three 1.3s linear infinite',
    '-o-animation': 'cssload-rotate-three 1.3s linear infinite',
    '-ms-animation': 'cssload-rotate-three 1.3s linear infinite',
    '-webkit-animation': 'cssload-rotate-three 1.3s linear infinite',
    '-moz-animation': 'cssload-rotate-three 1.3s linear infinite',
    'border-top': '9px solid white',
  },
  spinningCircle: {
    position: 'absolute',
  },
};

class Loading extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.loadingPage}>
        <div className={classes.cssloadLoader}>
          {/*}
					<div className={classes.cssloadInner + " " + classes.cssloadOne}/>
					<div className={classes.cssloadInner + " " + classes.cssloadTwo}/>
					<div className={classes.cssloadInner + " " + classes.cssloadThree}/>
					*/}
        </div>
        <img src={GunthyLogo} className={classes.spinningCircle} style={{ width: '180px' }} />
      </div>
    );
  }
}

export default connect(() => ({}), { dispatch })(withStyles(styles)(Loading));
