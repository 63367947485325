import React, { useState, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Box, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import logo from 'assets/img/logo-s2-white2x.png';

import { createTheme, makeStyles } from '@material-ui/core/styles';

import { dispatch as CustomDispatch } from 'redux/actions/index';
import { selectTwoFARequired, selectDemoStatus, selectIsRequiredMetaMask } from 'redux/selectors/index';

import Classes from './BackgroundVideo.module.css';
import { SocketContext } from '../../SocketIO';
import { sendTokenViaSocket } from 'assets/js/common';
import ConnectWalletButton from 'components/MetaMask/ConnectWalletButton';
import { Web3ManageContext } from 'providers/Web3ManageProvider';

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        color: '#252525',
      },
      input: {
        color: '#9e9e9e',
        textAlign: 'center',
        marginBottom: '5px',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#9e9e9e',
        marginBottom: '5px',
        marginLeft: '5px',
        marginTop: '-7px',
        '&$focused': {
          color: '#196dc8',
          marginTop: '0px',
        },
      },
    },
  },
});

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginWrapper: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '1rem',
  },
  title: {
    color: 'white',
    textAlign: 'center',
  },
  error: {
    color: 'red',
  },
  Container: {
    position: 'relative',
    //"min-height": "1200px",
    'max-height': '100vh',
    overflow: 'hidden',
    backgroundSize: 'cover',
  },
  /* Style the video: 100% width and height to cover the entire window */
  Video: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
  },
};

const useStyle = makeStyles(styles);

function LoginView() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const { account } = useContext(Web3ManageContext);
  const error = useSelector(state => state.settings.error);
  const isTwoFA = useSelector(selectTwoFARequired);
  const isRequiredMetaMask = useSelector(selectIsRequiredMetaMask);
  const isDemo = useSelector(selectDemoStatus);

  const [state, setState] = useState({
    password: '',
    twoFA: '',
  });

  const handlePasswordChange = useCallback(event => {
    event.persist();
    setState(prev => ({ ...prev, password: event.target.value }));
  }, []);

  const handleTwoFAChange = useCallback(event => {
    event.persist();
    setState(prev => ({ ...prev, twoFA: event.target.value }));
  }, []);

  const handleLogin = useCallback(() => {
    const { password, twoFA } = state;
    let walletAddress = account;
    if (!isRequiredMetaMask) {
      walletAddress = undefined;
    }
    dispatch(CustomDispatch('login', password, twoFA, walletAddress)).then(() => {
      sendTokenViaSocket(socket);
    });
  }, [account, state, isRequiredMetaMask]);

  return (
    <div className={Classes.Container} style={{ minHeight: '100vh', background: '#000' }}>
      <div className={Classes.Content}>
        <Paper elevation={3} style={{ minWidth: '25%', background: '#181818' }} className={classes.root}>
          <Box p={{ xs: 0, sm: 1, md: 2 }} width="100%">
            <Box textAlign="center">
              <Box src={logo} component="img" alt={'logo'} height={50} />
              {error && <div className={classes.error}>{error.message}</div>}
            </Box>
            <TextField
              type="password"
              label={isDemo ? 'Password = demo' : 'Password'}
              variant="outlined"
              fullWidth
              value={state.password}
              onChange={handlePasswordChange}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  handleLogin();
                }
              }}
              style={{ marginTop: '1rem' }}
            />

            {isTwoFA && (
              <TextField
                type="text"
                label="2FA Code"
                variant="outlined"
                inputMode="numeric"
                pattern="[0-9]*"
                value={state.twoFA}
                fullWidth
                onChange={handleTwoFAChange}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    {
                      handleLogin();
                    }
                  }
                }}
                style={{ marginTop: '1rem' }}
              />
            )}

            {isRequiredMetaMask && !isDemo && <ConnectWalletButton width="100%" mt={4} size="large" />}

            <Box mb={2} mt={4}>
              <Button variant="contained" fullWidth size="large" color="primary" onClick={handleLogin}>
                Login
              </Button>
            </Box>

            <Box textAlign="right">
              <Typography
                component="a"
                href="https://wiki.gunthy.org/troubleshooting/reset-password"
                target="_blank"
                rel="noopener noreferrer"
                color="textSecondary"
                style={{ textDecoration: 'none', fontSize: 14 }}
              >
                Forgot password?
              </Typography>
            </Box>
          </Box>
        </Paper>
      </div>
    </div>
  );
}

export default LoginView;
