import React from 'react';
import { connect } from 'react-redux';
import { TextField, Button, createTheme, ThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import logo from 'assets/img/logo-s2-white2x.png';
import Classes from './BackgroundVideo.module.css';

import { dispatch } from 'redux/actions/index';
import { selectIsAuth, selectIsRegistered, selectTwoFARequired } from 'redux/selectors/index';

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      root: {
        color: '#252525',
      },
      input: {
        color: '#9e9e9e',
        textAlign: 'center',
        marginBottom: '5px',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#9e9e9e',
        marginBottom: '5px',
        marginLeft: '5px',
        marginTop: '-7px',
        '&$focused': {
          color: '#f0b90b',
          marginTop: '0px',
        },
      },
    },
  },
});

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginWrapper: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: 'white',
    textAlign: 'center',
  },
  error: {
    color: 'red',
  },
  loginButton: {
    marginTop: '2rem',
    marginBottom: '1rem',
    background: '#f0b90b',
    '&:hover': {
      background: '#a88107',
    },
    color: 'black',
  },
  Container: {
    position: 'relative',
    //"min-height": "1200px",
    'max-height': '100vh',
    overflow: 'hidden',
    backgroundSize: 'cover',
  },
  /* Style the video: 100% width and height to cover the entire window */
  Video: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
  },
  logo: {
    height: 35,
    marginBottom: '1rem',
  },
};

class Register extends React.Component {
  state = {
    password: '',
    twoFA: '',
  };

  componentDidMount() {}

  componentWillUnmount() {}

  redirectToSetup = history => {
    const search = history.location.search;
    const currentHost = window.location.href.split('register')[0];
    setTimeout(() => {
      window.location.replace(currentHost + 'setup' + search);
    }, 1200);
  };

  render() {
    const { isAuth, isRegistered, isTwoFA, classes, history } = this.props;
    if (isAuth || isRegistered) {
      return <Redirect to="/" />;
    }

    return (
      <div className={Classes.Container} style={{ minHeight: '100vh', background: '#000' }}>
        <div className={Classes.Content}>
          <Paper
            elevation={3}
            className={classes.root}
            style={{
              minWidth: '25%',
              background: '#181818',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={logo} alt={'logo'} className={classes.logo} />
              <p style={{ fontSize: 'normal', color: '#9e9e9e', marginBottom: '2rem' }}>
                Set a password for this bot instance
              </p>
              <ThemeProvider theme={theme}>
                <TextField
                  type="password"
                  label="Password"
                  style={{ marginTop: '0.5rem', width: '250px', background: '#252525' }}
                  value={this.state.password}
                  onChange={event => {
                    this.setState({ password: event.target.value });
                  }}
                />
                {isTwoFA && (
                  <TextField
                    type="text"
                    label="TwoFA"
                    style={{ marginTop: '.5rem', width: '250px' }}
                    value={this.state.twoFA}
                    onChange={event => {
                      this.setState({ twoFA: event.target.value });
                    }}
                  />
                )}
              </ThemeProvider>

              <Button
                variant="contained"
                color="primary"
                className={classes.loginButton}
                style={{ width: '250px' }}
                onClick={() => {
                  this.props.dispatch('register', this.state.password, this.state.twoFA).then(() => {
                    this.redirectToSetup(history);
                  });
                }}
              >
                Save password
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    isAuth: selectIsAuth(state),
    isRegistered: selectIsRegistered(state),
    isTwoFA: selectTwoFARequired(state),
  }),
  { dispatch },
)(withStyles(styles)(Register));
